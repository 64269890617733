.mobile-navbar {
    max-height: 85vh;
    min-height: 260px;
    overflow-y: auto;
    min-width: 240px;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
    }
    &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #1f2937 0%, #1f2937 100%);
        border-radius: 5px;
    }
}
@media screen and (max-width: 1135px) {
    .flex-none.sideBar_hiddenScroll__xJ90y.w-72.min-w-\[18rem\].hide-sidebar-sm\:hidden.collapse-sidebar-md\:w-20.bg-dark-purple.h-screen.p-5.pt-8.relative.duration-300.overflow-y-scroll {
        display:none;
    }
    .hidden.sm\:flex.justify-end.items-center {
        display: block;
    }
}
