.scrollerBar {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #FF95B5 0%, #C93A87 100%);
        border-radius: 5px;
    }
}