.contentTable {
  @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;

  thead {
    @apply text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400;

    th {
      @apply py-3 px-2 sm:px-2 text-center;
    }
  }

  tbody {
    tr {
      @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700;

      td {
        @apply py-2 px-2 text-center;

        .wrapBtn {
          @apply flex flex-row justify-center gap-2;

          button {
            @apply text-white focus:outline-none focus:ring-4  font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900;
          }
        }
      }
    }
  }
}

.contentTableSub {
  @apply w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-10;

  thead {
    @apply text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400;

    th {
      @apply py-3 px-2 sm:px-2 text-center;
    }
  }

  tbody {
    tr {
      @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700;

      td {
        @apply py-2 px-2 text-center;

        .wrapBtn {
          @apply flex flex-row justify-center;

          button {
            @apply m-auto text-white focus:outline-none focus:ring-4  font-medium rounded-xl text-sm px-5 py-1.5 text-center dark:focus:ring-yellow-900;
          }
        }
      }
    }
  }
}

.forImage,
.forBannerImage {
  @apply relative flex-none m-auto;
  width: 6rem;
  height: 6rem;
  &:hover {
    .circle {
      opacity: 1;
      transition: 1s opacity;
    }
    i {
      opacity: 1;
      transition: 1s opacity;
    }
  }
  img {
    @apply w-full h-full sm:mx-auto shadow-lg cursor-pointer object-cover;

    border-radius: 5px;
  }
  input {
    @apply absolute h-full w-full;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  i {
    position: absolute;
    top: 38%;
    left: 0%;
    right: 0;
    margin: auto;
    color: white;
    font-size: x-large;
    opacity: 0;
    pointer-events: none;
  }
  .circle {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 27%;
    height: 45px;
    width: 45px;
    z-index: 10;
    border: 2px solid white;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 99rem;
  }
}

.forBannerImage {
  width: 16rem !important;
}

.dialogPanel {
  @apply w-full max-w-2xl max-h-[85vh] transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ff95b5 0%, #daa9c3 100%);
    border-radius: 5px;
  }
}

.modalOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  overflow-x: auto;
}

.closeButton {
  position: absolute;
  top: -18px;
  right: 5px;
  font-size: 36px;
  background: transparent;
  color: black;
  border: transparent;
  cursor: pointer;
}

.closeButton:hover {
  scale: 1.2;
  color: red;
}

.contentModal {
  @apply w-full h-[400px] border border-gray-300 dark:border-gray-600;
}
