$mobile: "only screen and (max-width : 767px)";
$tablet: "only screen and (min-width : 768px)";
$desktop: "only screen and (min-width : 1025px)";

.detail_pay {
    position: relative;

    .column_detail_pay {
        margin: 1rem;
        padding: 0 1rem;

        @media #{$mobile} {
            margin: 0rem;
            padding: 0rem;
        }

        .text_top {
            @apply my-4;
            text-align: center;
            p {
                font-weight: 600;
                font-size: 20px;
            }
        }

        .column_table {
            border: 1px solid #B8B8B8;
            border-radius: 10px;


            table {
                border-collapse: collapse;
                width: 100%;
                background: #FAFAFA;
                border: none;
                // border: 1px solid #B8B8B8;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
                border-radius: 10px;

                td {
                    padding: 8px;
                    text-align: left;
                    border-bottom: 1px solid #B8B8B8;
                }

                th { 
                    padding: 8px;
                    text-align: left;
                    border-bottom: 1px solid #B8B8B8;
                    color: #FFFFFF;
                    // background: linear-gradient(90deg, #C93A87 0%, #FF95B5 70%);
                }

                .td_empty{
                    background: linear-gradient(90deg, #C93A87 0%, #FF95B5 70%);
                }

                .td_right {
                    border-left: 1px solid #B8B8B8;
                }
            }
        }

        .column_detail_member {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #FAFAFA;
            border-radius: 10px;

            p{
                text-align: center;
                margin: 1rem;
                font-weight: 600;
            }

            .text_column {
                display: flex;
                background: #FAFAFA;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                margin-bottom: 1rem;
               
                img {
                    width: 100%;
                    height: 320px;
                    background: #D9D9D9;
                    font-size: 25px;
                    object-fit: contain;
                    border-radius: 10px;

                    @media #{$mobile} {
                        width: 100%;
                        height: 267px;
                        background: #D9D9D9;
                        font-size: 25px;
                        object-fit: contain;
                        border-radius: 10px;

                    }
                }

            }
        }


        .btn-bottom {
            text-align: center;

            .btn-out {
                width: 100%;
                height: 50px;
                margin: 2.5rem auto;
                text-align: center;
                background: linear-gradient(180deg, #FF95B5 0%, #C93A87 100%);
                border-radius: 10px;
                border: none;
                color: white;
                font-size: 25px;

                &:hover {
                    background: linear-gradient(180deg, #FFFFFF 0%, #FFCCDE 100%);
                    border: 2px solid #FF1C88;
                    border-radius: 10px;
                    color: #FF1C88;
                }

                @media #{$mobile} {
                    width: 600px;
                }
            }
        }

    }
}