.content {
  @apply h-screen flex-1 p-4 sm:px-0 pt-12 max-h-screen overflow-auto;

  .wrapBtnBack {
    @apply relative m-3 text-left gap-2 flex align-middle;

    button {
      @apply flex gap-2 align-middle;

      i {
        @apply flex my-auto text-pink-500 duration-200 cursor-pointer text-xl;
      }

      p {
        @apply text-pink-500;
      }
    }
  }

  .contentTable {
    @apply relative mt-5 border-2 rounded-lg mx-auto;
    
    table {
      @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;

      thead {
        @apply text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400;

        th {
          @apply py-3 px-6 text-center;
        }
      }
      tbody {
        tr {
          @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700;

          td {
            @apply py-2 px-6 text-center;

            
          }
        }
      }
    }

    .contentBottom {
      @apply flex flex-wrap flex-col gap-2 justify-center;

      .contentPayment {
        @apply flex flex-col gap-3 p-3 place-items-center my-auto;

        p {
          font-size: 20px;
          font-weight: 400;
        }
        img {
          @apply sm:min-w-full rounded;
          width: 80%;
          height: 100%;
        }
      }

      .contentProduct {
        @apply flex flex-wrap justify-center gap-5 border-2 rounded border-gray-200 m-2 p-2;
        background-color: rgba(0, 0, 0, 0.5);

        .title {
          position: absolute;
          left: 1.1rem;
          font-weight: 600;
          font-size: 20px;
          color: black;
          width: 10rem;
          background-color: aliceblue;
        }
        .product {
          @apply rounded-lg shadow-lg bg-white max-w-xs relative pt-4 lg:mt-10 md:mt-12 mt-12;
          width: 93%;
          img {
            @apply mx-auto rounded-t-lg;
            width: 180px;
            height: 180px;
            object-fit: cover;
          }

          .productDetail {
            @apply p-6;

            .pd_name {
              @apply text-gray-900;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .pd_content {
              @apply text-gray-700 text-base;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .pd_status {
              @apply inline-block px-6 py-1.5 text-white font-medium text-xs leading-tight uppercase shadow-md;
            }
          }
        }
      }
    }

    .reportOrder {
      @apply flex flex-row justify-center items-center mx-auto;
      height: 2rem;
      width: 170px;

      button {
        @apply gap-2 flex text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center dark:focus:ring-red-900;
      }
      }
    .reportProduct {
      @apply flex flex-row justify-center items-center mx-auto;
      height: 2rem;
      width: 150px;

      button {
        @apply gap-2 flex text-red-400 hover:text-red-500 hover:scale-110 duration-75 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-xl text-sm px-5 py-1.5 text-center dark:focus:ring-red-900;
      }
      }
  }
}
