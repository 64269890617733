.content {
  @apply h-screen flex-1 pt-12 pb-10  max-h-screen overflow-auto;

  .title {
    @apply text-2xl font-semibold;
  }

  .contentTab {
    @apply flex flex-col gap-2 flex-wrap justify-center pt-6 mx-auto;
    // max-width: 1100px;

    .tabList {
      @apply flex flex-row mx-auto space-x-1 rounded-xl bg-pink-900/20 p-1;
      width: 220px;
      height: 35px;
    }
  }
}
