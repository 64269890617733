$mobile: "only screen and (max-width : 767px)";
$tablet: "only screen and (min-width : 768px)";
$desktop: "only screen and (min-width : 1025px)";

.displayScroller {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ff95b5 0%, #daa9c3 100%);
    border-radius: 5px;
  }
}

.column_box_product {
  background: #ffffff;
  border: 1px solid #b8b8b8;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  // height: 844px;

  .text_box_top {
    background: linear-gradient(180deg, #ff95b5 0%, #c93a87 100%);
    border-radius: 9px 9px 0px 0px;
    color: white;
    text-align: left;
    padding: 0.5rem 1rem 0.5rem 1rem;

    h2 {
      font-size: 17px;

      @media #{$mobile} {
        font-size: 16px;
      }
    }

    p {
      font-size: 17px;

      @media #{$mobile} {
        font-size: 16px;
      }
    }
  }

  .column_product_recommend {
    overflow-y: auto;
    padding: 0.7rem 0.7rem;
    column-gap: 1rem;
    row-gap: 3rem;

    @media #{$mobile} {
      padding: 0rem 0rem;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
    }

    &::-webkit-bar-thumb {
      background: linear-gradient(180deg, #ff95b5 0%, #daa9c3 100%);
      border-radius: 5px;
    }

    @media #{$mobile} {
      padding: 0;
    }

    .recommend_column {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      padding: 1rem;
      margin: 1rem 0;
      box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
      .icon_trash {
        @apply absolute right-8 text-red-500 hover:animate-ping z-10 opacity-75 cursor-pointer;
      }
      .column_calendar {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-weight: 600;
      }

      .detail_text {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin: 0.5rem;
        text-align: left;
        font-weight: 500;
      }

      .column_img {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        align-items: center;

        @media #{$mobile} {
          grid-template-columns: 1fr 1fr;
        }

        img {
          width: 90%;
          height: 165px;
          background: #d9d9d9;
          font-size: 25px;
          object-fit: cover;
          border-radius: 10px;

          @media #{$mobile} {
            width: 100%;
            height: 165px;
          }
        }
      }
    }
  }
}
