.content {
  width: 100%;
  height: 100%;
  // background: linear-gradient(180deg, #fff2f9 12%, rgb(255 255 255 / 10%) 80%);

  .contentBody {
    @apply h-screen flex-1 p-8 sm:p-4 pb-10 gap-2 max-h-screen overflow-auto;

    .title {
      @apply text-xl font-semibold m-6 text-left text-gray-800;
    }

    .flexCol {
      @apply w-full flex flex-col gap-10 sm:gap-2 mx-auto;

      .flexRow {
        @apply flex flex-row gap-2 for-sidebar:justify-center justify-between sm:grid sm:grid-cols-2;

        .box {
          @apply rounded bg-slate-200 hover:shadow-lg duration-100;
          height: 150px;
          // width: 100%;

          .boxBg {
            @apply flex justify-between items-center px-4 w-full rounded-t;
            height: 70%;
          }

          .boxFooter {
            @apply flex justify-between items-center px-4  w-full rounded-b;
            height: 30%;
          }
        }
      }

      .flexTopRow {
        @apply flex flex-row mx-auto gap-4 flex-wrap w-full drop-shadow-md ;

        .orderList {
          @apply w-full lg:w-[68%] mr-auto flex justify-center items-center rounded bg-white drop-shadow-md;

          .box {
            @apply w-full h-full bg-white rounded-sm pb-4 p-2;

            .titleBest {
              @apply flex justify-center items-center text-lg text-center mb-2;
            }

            .overFlow {
              overflow-y: auto;
              width: 100%;
              height: 21rem;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
              }

              &::-webkit-scrollbar-thumb {
                background: linear-gradient(180deg, #ff95b5 0%, #c93a87 100%);
                border-radius: 5px;
              }
            }
          }
        }
        .bestSeller {
          @apply w-full lg:w-[30%] ml-auto flex justify-center items-center rounded bg-white drop-shadow-md;
          .box {
            @apply w-full h-full bg-white rounded-sm pb-4 p-2;

            .titleBest {
              @apply text-lg mb-2;
            }

            .overFlow {
              overflow-y: auto;
              width: 100%;
              height: 100%;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
              }

              &::-webkit-scrollbar-thumb {
                background: linear-gradient(180deg, #ff95b5 0%, #c93a87 100%);
                border-radius: 5px;
              }
            }
          }
        }
      }

      .flexBottomRow {
        @apply flex flex-row mx-auto gap-4 flex-wrap w-full drop-shadow-md ;

        .barChart {
          @apply w-full lg:w-[68%] mr-auto flex justify-center items-center rounded bg-white drop-shadow-md;

          .chart {
            @apply w-full h-full bg-white rounded-sm p-4;

            .titleChart {
              @apply text-lg;
            }
          }
        }
        .polarChart {
          @apply w-full lg:w-[30%] ml-auto flex justify-center items-center rounded bg-white drop-shadow-md;

          .chart {
            @apply w-full h-full bg-white rounded-sm py-4;
            max-width: 20rem;

            canvas {
              width: 100% !important;
              height: 100% !important;
            }
            .overFlow {
              overflow-y: auto;
              width: 100%;
              height: 20rem;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
              }

              &::-webkit-scrollbar-thumb {
                background: linear-gradient(180deg, #ff95b5 0%, #c93a87 100%);
                border-radius: 5px;
              }
            }
            .titleChart {
              @apply text-lg;
            }
          }
        }
      }
    }
  }
}
