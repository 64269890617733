.setting-page {
    .first-rows {
        @apply flex flex-col lg:flex-row;

        .column-setting {
            @apply w-full flex flex-col justify-evenly overflow-x-auto relative mx-auto  rounded-lg p-4;
            // max-width: calc(100% - 18rem);
            @media screen and (max-width: 900px) {
                width: 100%;
                max-width: 100%;
            }
            .settings-box {
                @apply flex items-center justify-center gap-4 mb-2;
                .left-content {
                    @apply flex rounded-md shadow-sm h-10;
                    width: 15rem;
                    @media screen and (max-width: 900px) {
                        width: 9rem;
                    }
                    input {
                        @apply text-center block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm;
                    }
                    span {
                        @apply inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500;
                    }
                }
                .btn-confirm {
                    @apply bg-green-600 hover:bg-green-700 text-white rounded h-10;
                    width: 4rem;
                }

                .head-title-file {
                    @apply flex;
                    border-radius: 5px;
                    background-color: rgb(217, 220, 224);
                    box-shadow:
                        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    
                    .custom-file-upload {
                        margin-right: 0.5rem;
                        color: black;
                        min-width: 230px;
                        height: 40px !important;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        padding-left: 0.5rem;
                        text-align: center;
                        cursor: pointer;
                    }
    
                    .custom-file-upload:hover {
                        text-decoration: underline;
                    }
    
                    .custom-file-upload.error {
                        color: rgb(194, 64, 64);
                    }
    
                    input[type="file"] {
                        display: none;
                    }
    
                    .btn-fileupload {
                        border-radius: 0 5px 5px 0;
                    }
                }

                .btn-upload-manual {
                    @apply bg-orange-400 hover:bg-orange-500 text-white rounded h-10;
                    width: 4rem;
                }

                .forImage {
                    @apply relative mr-4 flex-none;
                    width: 6rem;
                    height: 6rem;
                    &:hover {
                        .circle {
                            opacity: 1;
                            transition: 1s opacity;
                        }
                        i {
                            opacity: 1;
                            transition: 1s opacity;
                        }
                    }
                    img {
                        @apply w-full h-full sm:mx-auto shadow-lg cursor-pointer object-cover;

                        border-radius: 5px;
                    }
                    input {
                        @apply absolute h-full w-full;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 2;
                        cursor: pointer;
                    }
                    i {
                        position: absolute;
                        top: 38%;
                        left: 0%;
                        right: 0;
                        margin: auto;
                        color: white;
                        font-size: x-large;
                        opacity: 0;
                        pointer-events: none;
                    }
                    .circle {
                        pointer-events: none;
                        opacity: 0;
                        position: absolute;
                        top: 27%;
                        height: 45px;
                        width: 45px;
                        z-index: 10;
                        border: 2px solid white;
                        left: 0;
                        right: 0;
                        margin: auto;
                        border-radius: 99rem;
                    }
                }
            }
        }
    }
    .second-rows {
        @apply flex flex-col lg:flex-row mt-8 pb-8;
        .column-setting {
            @apply w-full flex flex-col  overflow-x-auto relative mx-auto rounded-lg p-4;
            // max-width: 600px;

            @media screen and (max-width: 900px) {
                width: 100%;
                // max-width: 380px;
            }

            .web_extra_setting {
                @apply w-full h-auto flex flex-col mb-4;

                .settings-box-webextra {
                    @apply flex flex-col md:flex-row items-center justify-center mt-4 gap-4;
                    .left-content {
                        @apply flex rounded-md shadow-sm h-10;
                        width: 15rem;
                        @media screen and (max-width: 900px) {
                            width: 9rem;
                        }
                        input {
                            @apply text-center block w-full flex-1 rounded-none rounded-l-md border-gray-300 focus:border-pink-500 focus:ring-pink-500 sm:text-sm;
                        }
                        span {
                            @apply inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500;
                        }
                    }
                    .btn-confirm {
                        @apply bg-green-600 hover:bg-green-700 text-white rounded h-10;
                        width: 4rem;
                    }
                    .forImage {
                        @apply relative mr-4 flex-none;
                        width: 14rem;
                        height: 6rem;
                        &:hover {
                            .circle {
                                opacity: 1;
                                transition: 1s opacity;
                            }
                            i {
                                opacity: 1;
                                transition: 1s opacity;
                            }
                        }
                        img {
                            @apply w-full h-full sm:mx-auto shadow-lg cursor-pointer object-cover;

                            border-radius: 5px;
                        }
                        input {
                            @apply absolute h-full w-full;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 2;
                            cursor: pointer;
                        }
                        i {
                            position: absolute;
                            top: 38%;
                            left: 0%;
                            right: 0;
                            margin: auto;
                            color: white;
                            font-size: x-large;
                            opacity: 0;
                            pointer-events: none;
                        }
                        .circle {
                            pointer-events: none;
                            opacity: 0;
                            position: absolute;
                            top: 27%;
                            height: 45px;
                            width: 45px;
                            z-index: 10;
                            border: 2px solid white;
                            left: 0;
                            right: 0;
                            margin: auto;
                            border-radius: 99rem;
                        }
                    }
                }
            }

            .settings-box {
                margin-top: 1rem !important;
                display: flex;
                justify-content: space-evenly;
                margin: auto;
                max-width: 16rem;
            }
            .tag-reserved-word {
                @apply inline-flex items-center uppercase px-3 py-1 rounded-full bg-white text-gray-400 border mr-4 hover:border-red-600 mb-2;
                &.addword {
                    @apply bg-green-300 text-green-600;
                    border: none;
                    cursor: pointer;
                }
                &:hover {
                    i.fa-minus {
                        @apply hover:text-red-700;
                    }
                }
                i {
                    cursor: pointer;
                }
            }
        }
    }
}

.gp-setting-table {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding-top: 2rem;

    .tb-header {
        @apply flex justify-between;
        h2 {
            font-weight: 700;
        }
        .add-btn {
            @apply text-sm rounded-full bg-blue-400 text-gray-100 hover:text-white px-2 py-1 hover:shadow-lg w-24;
            transition-duration: 0.2s;
        }
    }
    .tb-body {
        @apply border mt-3;
        thead {
            @apply border;
        }
        tbody {
        }
        tr {
            @apply grid;
            grid-template-columns: 1fr 3fr 1fr;
            th,
            td {
                @apply min-w-[6rem];
                font-size: 1rem;
                line-height: 2rem;
            }

            .btn-remove {
                @apply border hover:border-pink-400 text-sm rounded-full h-6 bg-pink-400 text-gray-100 hover:text-white hover:shadow-lg w-20;
                padding: 1px 3px;
                transition-duration: 0.2s;
                i {
                    text-align: start;
                    width: 15px;
                    font-size: 11px;
                }
            }
            &:first-child,
            &:last-child {
                margin: 5px 0;
            }
        }
    }
}
