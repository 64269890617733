.main-page {
    @media screen and (max-width: 900px) {
        padding: 0 1rem;
    }
    @media screen and (min-width: 900px) {
        // max-width: calc(100% - 18rem);
        width: 100%;
        padding: 0 1rem;
    }
 
    .custom-table-detail  {
        max-width: 670px;
        overflow-x: scroll;
        @media screen and (min-width: 1278px) {
            max-width: 800px;
        }
        @media screen and (min-width: 1440px) {
            max-width: 900px;
        }
        @media screen and (min-width: 1600px) {
            max-width: 100%;
        }
    }
    
    .custom-table-report {
        @apply mt-4;
        width: 100%;
        overflow-x: scroll;
        @media screen and (min-width: 900px) {
            max-width: 550px;
        }
        @media screen and (min-width: 1000px) {
            max-width: 650px;
        }
        @media screen and (min-width: 1100px) {
            max-width: 100%;
        }
    }
}


 
