.content {
  @apply h-screen flex-1 p-4 pt-10 sm:p-0 sm:pt-5 overflow-auto;
  // @apply h-screen flex-1 p-4 pt-10 sm:p-0 sm:pt-5 for-sidebar:overflow-auto;

  .title:first-child {
    @apply text-2xl font-semibold my-4;
  }

  .title:not(:first-child) {
    @apply text-2xl font-semibold mb-4 mt-8;
  }
  .searchText {
    @apply relative sm:ml-auto sm:mr-4 my-4;

    input {
      @apply rounded flex px-3; 
    }

    i {
      @apply absolute right-3 text-gray-500/25;
      top: 32%;
    }
  }

  .contentTab {
    @apply flex flex-col gap-2 flex-wrap justify-center pt-2 mx-auto;
    // max-width: 1100px;

    .tabList {
      @apply flex flex-row mx-auto space-x-1 rounded-xl bg-pink-900/20 p-1;
      width: 220px;
      height: 35px;
    }
  }

  .contentTable {
    @apply max-w-full min-h-[210px] overflow-x-auto relative mx-auto border-2 rounded-lg mb-4;
  }
}

.dialogPanel {
  @apply w-full max-w-2xl max-h-[85vh] transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgb(196 196 196 / 30%);
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ff95b5 0%, #daa9c3 100%);
    border-radius: 5px;
  }
}
