.none {
    display: none;
}

@keyframes fadeIn {
    0% { z-index: 0; background-color: rgba(0, 0, 0, 0); }
    100% { z-index: 2; background-color: rgba(0, 0, 0, 0.5);}
  }

.body_loading {
    animation: fadeIn 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    color: aliceblue;
    width: 100%;
    z-index: 2;
}

.lds_ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds_ellipsis div {
    position: absolute;
    top: 33px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #eb094c ;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds_ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds_ellipsis1 0.6s infinite;
}

.lds_ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds_ellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds_ellipsis2 0.6s infinite;
}

.lds_ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds_ellipsis3 0.6s infinite;
}

@keyframes lds_ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds_ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds_ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}